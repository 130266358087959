export {};
const Autodesk = window.Autodesk;
const THREE = window.THREE;

class BIMAcademyModelLoader extends Autodesk.Viewing.Extension {
    constructor(viewer, options = {}) {
        super(viewer, options);
        this.viewer = viewer;
        this.options = options;
        this.sceneBuilder = null;
        this.modelBuilder = null;
        this.boxes = [];
    }

    async load() {
        this.raycaster = new THREE.Raycaster();

        this.sceneBuilder = await this.viewer.loadExtension(
            "Autodesk.Viewing.SceneBuilder"
        );
        this.modelBuilder = await this.sceneBuilder.addNewModel({
            conserveMemory: false,
            modelNameOverride: "My Custom Model"
        });

        this.addGeometry(123456, -10, -10, {
            r: 1,
            g: 0,
            b: 0
        });

        this.addGeometry(123457, 1, 1, { r: 0, g: 1, b: 0 });

        this.viewer.fitToView([123456], this.modelBuilder.model);

        // Move whole model -100 units in the x-direction
        const offset = new THREE.Vector3(-1000, -1000, 0);

        const tree = this.viewer.model.getData().instanceTree;

        tree.enumNodeFragments(123456, function(frag) {
            const fragProxy = this.viewer.impl.getFragmentProxy(
                this.viewer.model,
                frag
            );

            //do some work with fragment proxy
            //   const fragProxy = this.viewer.impl.getFragmentProxy(
            //     this.viewer.model,
            //     123456
            //   );

            fragProxy.getAnimTransform();

            const position = new THREE.Vector3(
                fragProxy.position.x + offset.x,
                fragProxy.position.y + offset.y,
                fragProxy.position.z + offset.z
            );

            fragProxy.position = position;

            fragProxy.updateAnimTransform();
            this.viewer.impl.invalidate(true);
            this.viewer.impl.sceneUpdated(true);
        });

        return true;
    }

    addGeometry(dbId, x, y, color) {
        const boxGeometry = new THREE.BufferGeometry().fromGeometry(
            new THREE.BoxGeometry(10, 10, 10)
        );
        const boxMaterial = new THREE.MeshPhongMaterial({
            color: new THREE.Color(color.r, color.g, color.b)
        });
        const boxMesh = new THREE.Mesh(boxGeometry, boxMaterial);
        //http://www.opengl-tutorial.org/beginners-tutorials/tutorial-3-matrices/
        boxMesh.matrix = new THREE.Matrix4().compose(
            new THREE.Vector3(x, y, 0), //position
            new THREE.Quaternion(0, 0, 0, 1), //direction
            new THREE.Vector3(1, 1, 1) //velocity???
        );
        boxMesh.dbId = dbId;
        this.boxes.push(boxMesh);

        this.modelBuilder.addMesh(boxMesh);
    }

    unload() {
        return true;
    }
}

Autodesk.Viewing.theExtensionManager.registerExtension(
    "BIMAcademy.ModelLoader",
    BIMAcademyModelLoader
);
